import {useContext} from 'react';
import PropTypes from 'prop-types';
import {UserContext} from '../../context/UserContext';

export default function SsoTrigger({children, ...props}) {
  const {ssoModalOpener} = useContext(UserContext);
  return (
    <button onClick={ssoModalOpener} type="button" {...props}>
      {children}
    </button>
  );
}

SsoTrigger.propTypes = {
  children: PropTypes.node,
};
SsoTrigger.defaultProps = {
  children: null,
};

export const SsoOpenerBtnPlasmicData = {
  name: 'SsoTrigger',
  props: {
    children: {
      type: 'slot',
      defaultValue: {
        type: 'text',
        value: 'Clic',
      },
    },
  },
  importPath: './components/Sso/SsoTrigger',
  isDefaultExport: true,
};
