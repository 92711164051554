// This file has been modified by our AST polish script
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import * as React from 'react';
import {PageParamsProvider as PageParamsProvider__} from '@plasmicapp/react-web/lib/host';
import {PlasmicApp} from '../plasmic_components/plasmic/placeit/PlasmicApp';
import {useRouter} from 'next/router';
function App() {
  // Use PlasmicApp to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicApp are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, PlasmicApp is wrapped by your project's global
  // variant context providers. These wrappers may be moved to
  // Next.js Custom App component
  // (https://nextjs.org/docs/advanced-features/custom-app).
  return (
    <PageParamsProvider__
      route={useRouter()?.pathname}
      params={useRouter()?.query}
      query={useRouter()?.query}
    >
      <PlasmicApp />
    </PageParamsProvider__>
  );
}
export default App;
export function getStaticProps() {
  return {
    props: {},
  };
}
